import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Login from './components/Login/Login/Login';
import Content from './forms/Content';
import NavBar from './components/Landing2/NavBar';

const App = () => {
  const { isAuthenticated, logout, isLoading, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        if (isAuthenticated) {
          await getAccessTokenSilently(); // Attempt to refresh token
        }
      } catch (error) {
        console.error("Token refresh failed. Logging out.", error);
        logout({ returnTo: window.location.origin }); // Force re-login if token refresh fails
      }
    };

    checkAuth();
  }, [isAuthenticated, getAccessTokenSilently, logout]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {!isAuthenticated ? (
        <div>
          <NavBar />
          <Login />
        </div>
      ) : (
        <Content handleLogout={() => logout({ returnTo: window.location.origin })} />
      )}
    </div>
  );
};

export default App;
