import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuthApp from './AuthApp';
import Landing2 from './components/Landing2/Landing2';
import GDPR from './components/GDPR/GDPR';
import './App.css';

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Router>
      <Routes>
          <Route exact path="/" element={<Landing2/>}/>
          <Route exact path="/app" element={<AuthApp/>}/>
          <Route exact path="/gdpr" element={<GDPR/>}/>
      </Routes>
    </Router>
  </React.StrictMode>,
);
