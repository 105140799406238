import React from 'react';
import Card from './Card';
import './About.css';

function About() {
    return (
        <section id="about" className="section">
            <div className="container-large">
                <div className="centre-text">
                    <h3 className="heading large">Simplify and accelerate your initial screenplay analysis</h3>
                </div>
                <div className="spacer-80"></div>
                <div className="w-layout-grid card-list">
                    <Card
                        imgSrc="https://cdn.prod.website-files.com/675ee4fe4314f55d5e79e7fc/675ee4fe4314f55d5e79e8bf_users-three-duotone.svg"
                        title="For Filmmakers, By Filmmakers"
                        description="Created by a fellow indie film producer, Screenplayer transforms your feature-length, short, or episodic script into an industry-standard breakdown in seconds - saving you hours of manual work. We designed it to support the indie filmmaking community, where filmmakers often work alone. Think of it as your personal production assistant - here to save you time and money."
                    />
                    <Card
                        imgSrc="https://cdn.prod.website-files.com/675ee4fe4314f55d5e79e7fc/675ee4fe4314f55d5e79e8ae_handshake-duotone.svg"
                        title="Data Privacy: Our Commitment"
                        description="We take data privacy seriously. Once your screenplay is processed, we delete it—no storing, no sharing. Your trust is paramount to us, and that’s why we have a stringent data privacy policy in place: once your screenplay is processed, we delete it. We do not use your screenplay for any AI learning, training or scraping."
                    />
                    <Card
                        imgSrc="https://cdn.prod.website-files.com/675ee4fe4314f55d5e79e7fc/675ee4fe4314f55d5e79e8b1_shooting-star-duotone.svg"
                        title="Empowering, Not Replacing"
                        description="There is a fear that AI may eventually render human jobs obsolete. But at Screenplayer, our philosophy is different. We’re here to help and facilitate, not replace. Screenplayer is your co-pilot producer, dealing with the initial mundane and tedious task, so you can analyse your screenplay like a seasoned producer in seconds."
                    />
                    <Card
                        imgSrc="https://cdn.prod.website-files.com/675ee4fe4314f55d5e79e7fc/675ee4fe4314f55d5e79e8b5_lightbulb-filament-duotone.svg"
                        title="Affordable & Accessible"
                        description="Screenplayer is priced for filmmakers at any level. We believe that outstanding tools should be accessible to all, regardless of budgets. That is why we have priced Screenplayer to be affordable, putting quality insights and planning tools well within reach of everyone."
                    />
                </div>
            </div>
        </section>
    );
}

export default About; 