import React from 'react';
import Footer from '../Landing2/Footer';
import NavBar from '../Landing2/NavBar';

function GDPR() {
    return (
        <div>
            <NavBar />
            <section className="section" style={{backgroundColor: 'white'}}>
                <div className="container-large">
                    <div className="privacy-content">
                        <h1>Privacy Policy</h1>
                        <div className="policy-dates">
                            <p><strong>Effective Date:</strong> 30.08.2023</p>
                            <p><strong>Last Updated:</strong> 05.02.2025</p>
                        </div>

                        <h2>1. Introduction</h2>
                        <p>Welcome to Screenplayer ("we," "our," "us"). We respect your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, store, and safeguard your information in compliance with the UK General Data Protection Regulation (UK GDPR) and the Data Protection Act 2018.</p>
                        <p>By using our services, you agree to the terms outlined in this policy. If you have any questions, please contact us at screenplayer.help@gmail.com.</p>

                        <h2>2. Data We Collect</h2>
                        <p>We collect and process the following personal data:</p>
                        <ul>
                            <li>Name and email address (for account creation and communication).</li>
                            <li>Number of screenplay uploads (for usage tracking, but not the screenplay content).</li>
                            <li>Website usage analytics (e.g., IP address, device type, pages visited).</li>
                            <li>Screenplays submitted for processing – We temporarily collect uploaded screenplays to generate reports. Once processing is complete, we do not store or retain the screenplay files in any form.</li>
                        </ul>
                        <p>We do not store uploaded screenplays or use them for AI training, machine learning, or any other form of data retention beyond the immediate processing required to generate analysis.</p>
                        <p>We do not process or store payment details. Transactions are handled by third-party payment processor Stripe, which process your data according to their privacy policies.</p>

                        <h2>3. How We Use Your Data</h2>
                        <ul>
                            <li>Account creation & authentication (including Google sign-in).</li>
                            <li>Providing and improving our services using analytics.</li>
                            <li>Processing payments via third-party providers.</li>
                            <li>Customer support and communication regarding your account.</li>
                            <li>Marketing emails (you can opt out anytime).</li>
                        </ul>

                        <h2>4. Data Retention</h2>
                        <p>We retain personal data for as long as necessary for the purposes outlined in this policy:</p>
                        <ul>
                            <li>Account data: Retained while your account is active and for up to 10 years after deletion for legal and regulatory purposes.</li>
                            <li>Website usage data: Retained for up to 24 months for analytics and service improvement.</li>
                            <li>Marketing data: Retained until you unsubscribe from marketing emails.</li>
                        </ul>
                        <p>After these periods, data is securely deleted or anonymized.</p>

                        <h2>5. Third-Party Services</h2>
                        <p>To provide our services, we use the following third-party providers:</p>
                        <ul>
                            <li>Cloud Storage & API Processing: OpenAI, third-party cloud services.</li>
                            <li>Payment Processing: Stripe.</li>
                            <li>Authentication and user management: AuthO</li>
                        </ul>
                        <p>These providers process data on our behalf and must comply with UK GDPR security standards.</p>

                        <h2>6. Your Rights Under UK GDPR</h2>
                        <ul>
                            <li>Right to Access – Request a copy of your data.</li>
                            <li>Right to Rectification – Correct inaccurate or incomplete data.</li>
                            <li>Right to Erasure ("Right to be Forgotten") – Request data deletion.</li>
                            <li>Right to Restriction – Limit how we process your data.</li>
                            <li>Right to Data Portability – Receive your data in a structured format.</li>
                            <li>Right to Object – Object to certain processing, including marketing.</li>
                            <li>Right to Withdraw Consent – Opt out of marketing emails at any time.</li>
                        </ul>
                        <p>To exercise your rights, contact us at screenplayer.help@gmail.com. We will respond within one month as required by law.</p>

                        <h2>7. Marketing Communications</h2>
                        <p>We may send marketing emails about new features, promotions, and updates.</p>
                        <p>You can unsubscribe anytime by replying with the "unsubscribe" to our email.</p>

                        <h2>8. Cookies & Tracking</h2>
                        <p>We do not use cookies or tracking technologies at this time. If this changes, we will update this policy and request user consent where necessary.</p>

                        <h2>9. Changes to This Policy</h2>
                        <p>We may update this Privacy Policy periodically. If changes are significant, we will notify you via email or a notice on our website.</p>
                        <p>Last updated: 5th of February 2025</p>

                        <h2>10. Complaints & Contact Information</h2>
                        <p>If you have any concerns about how we process your data, please contact us:</p>
                        <p>📧 help.screenplayer@gmail.com</p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default GDPR; 